import React from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import { InputName } from '../public/Intake/intake.data';
import { IntakeFormData } from '../public/Intake/IntakePage';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IInputProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	label?: string;
	className?: string;
	fullWidth?: boolean;
	register?: UseFormRegister<IntakeFormData>;
	name?: InputName;
	refIndex?: number;
}

export type InputValidations<T> = { [K in keyof T]: RegisterOptions };
interface IInputProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	name?: InputName;
}

const Input = ({
	label,
	fullWidth,
	children,
	className,
	disabled,
	register,
	name,
	refIndex,
	...props
}: IInputProps) => {
	const registeredFieldProps = name && register && { ...register(name) };

	return (
		<div
			className={`flex flex-col items-start${fullWidth ? ' w-full' : ''}`}
		>
			<span className="text-xs mb-1 text-gray-400 text-left ">
				{label}
			</span>
			<input
				id={`input-${refIndex?.toString()}`}
				className={`border-oldYellow py-2 px-0 w-full outline-none main-input border-0 border-b font-light bg-yellowed focus:border-0 focus:outline-none ring-0 ${
					disabled && 'opacity-75'
				} ${className}`}
				disabled={disabled ?? false}
				{...registeredFieldProps}
				{...props}
			/>
		</div>
	);
};

export default Input;
