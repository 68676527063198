import React, { useContext } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import useScroll from '../../hooks/useScroll';
import { InputName } from '../../intake.data';
import { IntakeContext } from '../../intake.provider';
import { IntakeFormData } from '../../IntakePage';
import Button from '../../../../components/Button';

type ChoicesProps = {
	inputName: InputName;
	choices: string[];
	setValue: UseFormSetValue<IntakeFormData>;
};

const Choices = ({ inputName, choices, setValue }: ChoicesProps) => {
	const { currentIndex } = useContext(IntakeContext);
	const { scroll } = useScroll();

	return (
		<div className="flex flex-col items-start">
			{choices.map((choice) => (
				<Button
					className="text-xl mb-2"
					type="button"
					key={choice}
					onClick={() => {
						if (inputName) {
							setValue(inputName, choice);
							scroll('down', currentIndex);
						}
					}}
				>
					{choice}
				</Button>
			))}
		</div>
	);
};

export default Choices;
