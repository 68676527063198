import React from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { IntakeQuestion } from '../intake.data';
import { IntakeFormData } from '../IntakePage';

type IntakeLabelProps = {
	section: IntakeQuestion;
	getValues: UseFormGetValues<IntakeFormData>;
};

const IntakeLabel = ({ section, getValues }: IntakeLabelProps) => {
	const isRequired = section.required;

	const renderLabel = (label: string): string => {
		if (section.injectVariable) {
			const newLabel = label.replace(
				section.injectVariable.to,
				getValues()[section.injectVariable.from] as any,
			);
			return newLabel;
		}
		return label;
	};

	return (
		<div className="flex flex-col">
			<div className="mb-4">
				{Array.isArray(section.label) ? (
					section.label.map((lab, i) => (
						<div key={lab} className="flex">
							<p
								dangerouslySetInnerHTML={{
									__html: renderLabel(lab),
								}}
								className="xl:text-2xl text-xl"
							/>
							{i === section.label.length - 1 && isRequired ? (
								<span className="xl:text-2xl text-xl ml-1">
									*
								</span>
							) : null}
						</div>
					))
				) : (
					<div className="flex">
						<p
							dangerouslySetInnerHTML={{
								__html: renderLabel(section.label),
							}}
							className="xl:text-2xl text-xl"
						/>
						{isRequired && (
							<span className="xl:text-2xl text-xl ml-1">*</span>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default IntakeLabel;
