import React, { useContext, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { uniqueId } from 'lodash';
import useScroll from '../../hooks/useScroll';
import { InputName } from '../../intake.data';
import { IntakeContext } from '../../intake.provider';
import { IntakeFormData } from '../../IntakePage';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';

type MultiSelectProps = {
	inputName: InputName;
	choices: string[];
	min?: number;
	setValue: UseFormSetValue<IntakeFormData>;
};

const MultiSelect = ({
	inputName,
	choices,
	setValue,
	min,
}: MultiSelectProps) => {
	const { currentIndex } = useContext(IntakeContext);
	const [moreSelected, setMoreSelected] = useState<boolean>(false);
	const [customChoice, setCustomChoice] = useState<string>('');
	const [selected, setSelected] = useState<{ id: string; text: string }[]>(
		[],
	);
	const { scroll } = useScroll();

	const minReached = selected.length >= (min ?? 0);

	const submit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		const { id, outerText } = e.currentTarget as EventTarget &
			HTMLButtonElement & { outerText: string };

		if (!selected.map((choice) => choice.id).includes(id)) {
			setSelected((prev) => [...prev, { id, text: outerText }]);
		} else {
			setSelected((prev) => prev.filter((choice) => choice.id !== id));
		}
	};

	const handleMore = () => {
		if (moreSelected) {
			setSelected((prev) =>
				prev.filter((choice) => choice.id !== 'Others'),
			);
			setCustomChoice('');
		}
		setMoreSelected((prev) => !prev);
	};
	const enterReason = () => {
		setSelected((prev) => [...prev, { id: 'Others', text: customChoice }]);
	};

	const handleNext = () => {
		if (inputName && minReached) {
			const texts = selected.map((s) => s.text);
			setValue(inputName, texts);
			scroll('down', currentIndex);
		}
	};
	return (
		<div className="flex flex-col items-start">
			{choices.map((choice, index) => (
				<Button
					className={`text-xl mb-2 text-left ${
						selected.map((c) => c.id).includes(index.toString()) &&
						'bg-yellow-500'
					}`}
					type="button"
					disabled={
						minReached &&
						!selected.map((c) => c.id).includes(index.toString())
					}
					id={index.toString()}
					key={uniqueId()}
					onClick={submit}
				>
					{choice}
				</Button>
			))}
			<Button
				className={`text-xl mb-2 ${moreSelected && 'bg-yellow-500'}`}
				type="button"
				disabled={
					minReached && !selected.map((c) => c.id).includes('Others')
				}
				onClick={handleMore}
			>
				Other
			</Button>
			{moreSelected && (
				<>
					<Input
						label="Choice"
						type="text"
						className="mb-4 py-4 px-4"
						fullWidth
						value={customChoice}
						placeholder="Choice"
						disabled={minReached}
						onChange={(e) => setCustomChoice(e.target.value)}
					/>
					{!minReached && (
						<Button
							type="button"
							onClick={enterReason}
							disabled={customChoice === ''}
						>
							Enter choice
						</Button>
					)}
				</>
			)}
			{minReached && (
				<Button type="button" className="m-auto" onClick={handleNext}>
					Next
				</Button>
			)}
		</div>
	);
};

export default MultiSelect;
