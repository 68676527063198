import * as React from 'react';
import {
	Show,
	TextField,
	ShowProps,
	DateField,
	Tab,
	TabbedShowLayout,
	BooleanField,
	ChipField,
	NumberField,
	SelectField,
	ArrayField,
	Datagrid,
	ReferenceField,
	FunctionField,
	UrlField,
	ImageField,
} from 'react-admin';
import LocalURLField from '../../components/LocalURLField';
import { cleanUserDetails } from '../../helpers';
import TextArrayField from '../components/TextArrayField';
import RemoveButton from '../living-rooms/components/remove.button';
import UserRefLink from './components/refLink';
import UserShowActions from './components/user.show.actions';
import {
	enumToSelection,
	UserCompanyFunction,
	UserCompanyIndustry,
	UserCompanyRole,
	UserCompanyType,
	UserEducation,
	UserEmploymentStatus,
	UserEthnicity,
	UserMobileOSOptions,
	UserOccupation,
	UserOccupationType,
	UserRelationship,
	UserSubscription,
} from './user.options';

type IUserShowProps = ShowProps;

const UserTitle = ({ record }: any) => {
	return (
		<span>
			{record
				? `${record.firstName} ${record.lastName} (${record.email})`
				: ''}
		</span>
	);
};

export const UserShow = (props: IUserShowProps) => {
	return (
		<Show title={<UserTitle />} actions={<UserShowActions />} {...props}>
			<TabbedShowLayout>
				<Tab label="summary">
					<TextField source="id" label="ID" emptyText="–" />
					<TextField source="firstName" emptyText="–" />
					<TextField source="lastName" emptyText="–" />
					<TextField source="email" emptyText="–" />
					<FunctionField
						label="Phone"
						render={(record: any) =>
							`${
								record.countryCode !== undefined
									? record.countryCode
									: ''
							}${record.phone}`
						}
					/>
					<TextField source="comment" emptyText="–" />
					<TextField source="role" emptyText="–" />
					<BooleanField source="contributor" />
					<BooleanField source="enterprise" />
					<BooleanField source="active" />
					<BooleanField source="locked" />
					<SelectField
						source="subscription"
						choices={enumToSelection(UserSubscription)}
						emptyText="–"
					/>
					<DateField source="created" showTime emptyText="–" />
					<DateField
						source="firstLogin"
						label="First Login Time"
						showTime
						emptyText="–"
					/>
					<DateField
						source="lastActivity"
						label="Last Time Active"
						emptyText="–"
						showTime
					/>
					<DateField
						label="Account Activation Date"
						source="accountActivatedAt"
						emptyText="–"
						showTime
					/>

					<DateField
						label="Subscription Activation Date"
						source="subscriptionActivatedAt"
						emptyText="–"
						showTime
					/>
					<FunctionField
						source=" "
						render={(record) => (
							<LocalURLField
								source="invitedBy.id"
								record={record}
								defaultValue={`${
									record.invitedBy
										? `${
												record.invitedBy?.firstName ??
												''
										  } ${record.invitedBy?.lastName ?? ''}`
										: ''
								}`}
								linkResource="users"
								label="Invited By"
							/>
						)}
					/>
					<FunctionField
						source=" "
						render={(record) => (
							<LocalURLField
								source="activatedByAdmin"
								record={record}
								defaultValue={`${
									record.activatedByAdmin
										? `${record.activatedByAdmin ?? ''}`
										: ''
								}`}
								linkResource="users"
								label="Activated By"
							/>
						)}
					/>
					<UserRefLink />
				</Tab>
				<Tab label="Personal">
					<TextField source="bio" label="Bio" emptyText="–" />
					<DateField
						source="dob"
						label="Date of birth"
						emptyText="–"
					/>
					<ImageField source="avatar" label="Avatar" emptyText="–" />
					<SelectField
						source="mobileOS"
						choices={[
							{
								id: UserMobileOSOptions[0],
								name: 'iOS',
							},
							{
								id: UserMobileOSOptions[1],
								name: 'Android',
							},
							{
								id: UserMobileOSOptions[2],
								name: 'Other',
							},
						]}
						label="Mobile Operating System"
					/>
					<TextField source="city" label="City/Town" emptyText="–" />
					<TextField source="state" label="State" emptyText="–" />
					<TextField source="country" label="Country" emptyText="–" />
					<SelectField
						source="relationship"
						choices={enumToSelection(UserRelationship)}
						emptyText="–"
					/>
					TextField
					<NumberField source="children" emptyText="–" />
					<ChipField source="ageOfChildren" label="Age of Children" />
					<TextField source="income" emptyText="–" />
					<SelectField
						source="ethnicity"
						choices={enumToSelection(UserEthnicity)}
						emptyText="–"
					/>
					<TextField
						source="generation"
						label="Generation"
						emptyText="–"
					/>
					<TextField source="pet" label="Pet" emptyText="–" />
				</Tab>
				<Tab label="Professional">
					<SelectField
						source="employment"
						label="Employment Status"
						choices={enumToSelection(UserEmploymentStatus)}
						emptyText="–"
					/>
					<TextField
						source="companyName"
						label="Organisation Name"
						emptyText="–"
					/>
					<TextField
						source="companySize"
						label="Organisation Size"
						emptyText="–"
					/>
					<SelectField
						source="companyType"
						label="Type of Organisation"
						choices={enumToSelection(UserCompanyType)}
						emptyText="–"
					/>
					<SelectField
						source="companyIndustry"
						label="Industry"
						choices={enumToSelection(UserCompanyIndustry)}
						emptyText="–"
					/>
					<SelectField
						source="companyRole"
						label="Role"
						choices={enumToSelection(UserCompanyRole)}
						emptyText="–"
					/>
					<SelectField
						source="companyFunction"
						label="Function"
						choices={enumToSelection(UserCompanyFunction)}
						emptyText="–"
					/>
					<SelectField
						source="education"
						label="Education"
						choices={enumToSelection(UserEducation)}
						emptyText="–"
					/>
					<TextField
						source="profiles"
						label="Workplace / LinkedIn Profile"
						emptyText="–"
					/>
					<SelectField
						source="occupation"
						label="Occupation (Role)"
						choices={enumToSelection(UserOccupation)}
						emptyText="–"
					/>
					<SelectField
						source="occupationType"
						label="Occupation Type  (Industry)"
						choices={enumToSelection(UserOccupationType)}
						emptyText="–"
					/>
					<UrlField
						source="resume.url"
						label="Resume"
						emptyText="–"
						target="_blank"
					/>
				</Tab>
				<Tab label="Platform">
					<TextArrayField
						source="howCanUplevylElevateYou"
						label="How can Uplevyl help you elevate your life?"
					/>
					<TextArrayField
						source="howCanUplevylHelpYou"
						label="How can Uplevyl help you?"
					/>

					<TextArrayField
						source="howCanYouHelpUplevyl"
						label="How can you help other Uplevylers?"
					/>
					<NumberField
						source="professionalGrowthImportance"
						emptyText="–"
					/>
					<NumberField
						source="personalGrowthImportance"
						emptyText="–"
					/>
					<NumberField
						source="financialGrowthImportance"
						emptyText="–"
					/>
					<NumberField
						source="activeParticipationImportance"
						emptyText="–"
					/>
					<NumberField
						source="womenSupportImportance"
						emptyText="–"
					/>
				</Tab>
				<Tab label="Living Rooms">
					<ArrayField source="registrations">
						<Datagrid>
							<ReferenceField
								label="Living Room"
								source="livingRoom.id"
								reference="livingRooms"
								link="show"
							>
								<TextField source="title" />
							</ReferenceField>
							<DateField source="livingRoom.start" label="Date" />
							<TextField source="role" />
							<RemoveButton />
						</Datagrid>
					</ArrayField>
				</Tab>

				<Tab label="Audit Logs">
					<ArrayField source="auditLog" label="Logs">
						<Datagrid>
							<FunctionField
								source=" "
								label="User"
								render={(record: any) => (
									<LocalURLField
										source="previousUserDetails.id"
										record={record}
										defaultValue={`${
											record.previousUserDetails
												? `${
														record
															.previousUserDetails
															?.firstName ?? ''
												  } ${
														record
															.previousUserDetails
															?.lastName ?? ''
												  }`
												: ''
										}`}
										linkResource="users"
									/>
								)}
							/>
							<FunctionField
								label="Fields Updated"
								render={(version: any) =>
									version.updatedUserDetails &&
									Object.keys(
										cleanUserDetails(
											version.updatedUserDetails,
										),
									).map((data) => (
										<ChipField
											key={data}
											record={{ id: data, name: data }}
											source="name"
										/>
									))
								}
							/>
							<DateField
								source="updated"
								label="Date & Time"
								showTime
							/>
						</Datagrid>
					</ArrayField>
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};
