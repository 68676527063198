import React from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import UserIcon from '@mui/icons-material/Group';
import ArticleIcon from '@mui/icons-material/VideoCall';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { Provider } from 'urql';
import ReportIcon from '@mui/icons-material/Gavel';
import { UserList } from './resources/users/user.list';
import Dashboard from './dashboard/Dashboard';
import buildAuthProvider from './providers/auth.provider';
import builDataProvider from './providers/data.provider';
import { UserEdit } from './resources/users/user.edit';
import { UserCreate } from './resources/users/user.create';
import { CustomLoginPage } from './Login';
// import { LivingRoomList } from './resources/living-rooms/living-room.list';
import { LivingRoomCreate } from './resources/living-rooms/living-room.create';
import { LivingRoomEdit } from './resources/living-rooms/living-room.edit';
import { LivingRoomShow } from './resources/living-rooms/living-room.show';
import { UserShow } from './resources/users/user.show';
import ActivatePage from './public/Activate/ActivatePage';
import ClientWrapper from './public';
import PublicArticle from './public/Article/PublicArticle';
import BillingSuccess from './public/Billing/Success';
import BillingError from './public/Billing/Error';
import IntakePage from './public/Intake/IntakePage';
import ConfirmationPage from './public/Intake/ConfirmationPage';
import Download from './public/Download/Download';
import Login from './public/Account/Login';
import Account from './public/Account/Account';
import CreateSupport from './public/Support/CreateSupport';
import SupportRequest from './public/Support/SupportRequest';
import ForgotPassword from './public/Account/ForgotPassword';
import IntakeProvider from './public/Intake/intake.provider';
import { ReportList } from './resources/reports/report.list';
import { ReportEdit } from './resources/reports/report.edit';
import { ReportShow } from './resources/reports/report.show';
import ScheduleCreatePage from './resources/custom/schedule/create';
import PollCreatePage from './resources/custom/schedule/create-poll';
import { PollShow } from './resources/polls/poll.show';
import { ScheduleList } from './resources/schedule/schedule.list';
import { FreeTextShow } from './resources/freeTexts/freeText.show';
import { QuoteShow } from './resources/quotes/quote.show';
import QuoteCreatePage from './resources/custom/schedule/create-quote';
import FreeTextCreatePage from './resources/custom/schedule/create-free-text';
import { PollEdit } from './resources/polls/poll.edit';
import { RedirectScheduleList } from './resources/custom/schedule/redirect-schedule-list';
import { FreeTextEdit } from './resources/freeTexts/freeText.edit';
import { QuoteEdit } from './resources/quotes/quote.edit';
import { lightTheme } from './layout/themes';
import Layout from './layout/Layout';
import { buildClient } from './providers/client/client.factory';
import { LivingRoomPage } from './resources/living-rooms/living-room.page';
import MobileRedirect from './MobileRedirect';
import MobileRedirectDeepLinking from './MobileRedirectDeepLinking';
import ResetPassword from './public/Account/ResetPassword';
import StripePayment from './public/Stripe/Stripe';

const App = () => {
	const client = buildClient('urql');
	// const history = createHistory({ basename: 'admin' });
	const stripePromise = loadStripe(
		process.env.REACT_APP_STRIPE_PUBLISH ??
			'pk_test_51IudWKLP7KCVbUfVSNxWQY3HwTQIos8EhuCFJ0EZPEcxLVGWF65zNWgByv6OIaTVq2jkGmX6UNe3kJNqw5AVBgCh00RbirkUKg',
	);
	return (
		<Provider value={client.getClient()}>
			<Elements stripe={stripePromise}>
				<BrowserRouter>
					<Routes>
						<Route
							path="/article/:id"
							element={<PublicArticle />}
						/>
						<Route
							path="/activate/:token"
							element={
								<ClientWrapper>
									<ActivatePage />
								</ClientWrapper>
							}
						/>
						<Route
							path="/login"
							element={
								<ClientWrapper>
									<Login />
								</ClientWrapper>
							}
						/>
						<Route
							path="/account"
							element={
								<ClientWrapper>
									<Account />
								</ClientWrapper>
							}
						/>
						<Route
							path="/forgot"
							element={
								<ClientWrapper>
									<ForgotPassword />
								</ClientWrapper>
							}
						/>
						<Route
							path="/reset"
							element={
								<ClientWrapper>
									<ResetPassword />
								</ClientWrapper>
							}
						/>
						<Route
							path="/redirect-mobile"
							element={
								<ClientWrapper>
									<MobileRedirect />
								</ClientWrapper>
							}
						/>
						<Route
							path="/payment"
							element={
								<ClientWrapper>
									<StripePayment />
								</ClientWrapper>
							}
						/>
						<Route
							path="/redirect-mobile-deep-linking"
							element={
								<ClientWrapper>
									<MobileRedirectDeepLinking />
								</ClientWrapper>
							}
						/>
						<Route
							path="/support"
							element={
								<ClientWrapper>
									<CreateSupport />
								</ClientWrapper>
							}
						/>
						<Route
							path="/support/:id"
							element={
								<ClientWrapper>
									<SupportRequest />
								</ClientWrapper>
							}
						/>
						<Route
							path="/billing/success"
							element={
								<ClientWrapper>
									<BillingSuccess />
								</ClientWrapper>
							}
						/>
						<Route
							path="/download"
							element={
								<ClientWrapper>
									<Download />
								</ClientWrapper>
							}
						/>
						<Route
							path="/billing/error"
							element={
								<ClientWrapper>
									<BillingError />
								</ClientWrapper>
							}
						/>
						<Route
							path="/referral/:username"
							element={<ConfirmationPage />}
						/>
						<Route
							key="admin"
							path="/admin/*"
							element={
								<ClientWrapper>
									<Admin
										title="Uplevyl Admin Panel"
										loginPage={CustomLoginPage}
										dataProvider={builDataProvider(client)}
										authProvider={buildAuthProvider(client)}
										// Custom components
										dashboard={Dashboard}
										theme={lightTheme}
										layout={Layout}
										basename="/admin"
										// history={history}
									>
										<Resource
											name="users"
											list={UserList}
											create={UserCreate}
											edit={UserEdit}
											show={UserShow}
											icon={UserIcon}
											options={{
												label: 'Members',
											}}
										/>
										<Resource
											name="livingRooms"
											options={{
												label: 'Living Rooms',
											}}
											show={LivingRoomShow}
											list={LivingRoomPage}
											create={LivingRoomCreate}
											edit={LivingRoomEdit}
											icon={ArticleIcon}
										/>
										<Resource
											name="reports"
											list={ReportList}
											edit={ReportEdit}
											show={ReportShow}
											icon={ReportIcon}
										/>
										<Resource name="milestones" />
										<Resource name="profile" />
										<Resource
											name="polls"
											show={PollShow}
											edit={PollEdit}
										/>
										<Resource
											name="schedules"
											list={ScheduleList}
											options={{
												label: 'Daily Posts',
											}}
										/>
										<Resource
											name="freeTexts"
											show={FreeTextShow}
											edit={FreeTextEdit}
										/>
										<Resource
											name="quotes"
											show={QuoteShow}
											edit={QuoteEdit}
										/>

										<CustomRoutes>
											<Route
												path="/schedule/create"
												element={<ScheduleCreatePage />}
											/>
											<Route
												path="/schedule/create-poll"
												element={<PollCreatePage />}
											/>
											<Route
												path="/schedule/create-quote"
												element={<QuoteCreatePage />}
											/>
											<Route
												path="/schedule/create-free-text"
												element={<FreeTextCreatePage />}
											/>
											<Route
												path="/polls"
												element={
													<RedirectScheduleList />
												}
											/>
											<Route
												path="/freeTexts"
												element={
													<RedirectScheduleList />
												}
											/>
											<Route
												path="/quotes"
												element={
													<RedirectScheduleList />
												}
											/>
										</CustomRoutes>
									</Admin>
								</ClientWrapper>
							}
						/>
						{/* </Route> */}

						<Route
							path="/"
							element={
								<IntakeProvider>
									<ClientWrapper>
										<IntakePage
											hash={
												window.location.hash ??
												undefined
											}
										/>
									</ClientWrapper>
								</IntakeProvider>
							}
						/>
					</Routes>
				</BrowserRouter>
			</Elements>
		</Provider>
	);
};

export default App;
