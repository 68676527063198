import { gql } from '@apollo/client';
import {
	UserGeneration,
	UserEducation,
	UserMobileOS,
} from '../types/intake.types';

export const CreateUserMutation = gql`
	mutation CreateUser($input: UserCreateInput!) {
		userCreate(input: $input) {
			id
			username
		}
	}
`;

export interface ICreateUserArgs {
	input: {
		firstName: string;
		email: string;
		lastName: string;
		education: UserEducation;
		phone: string;
		profiles?: string;
		termsAgreed: true;
		generation: UserGeneration;
		howCanUplevylElevateYou?: string[];
		inviteeToken?: string;
		inviteeUsername?: string;
		mobileOS: UserMobileOS;
	};
}

export interface ICreateUserMutationData {
	userCreate: {
		id: string;
		username: string;
	};
}
