import React, { useEffect, useState } from 'react';
import {
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormTrigger,
} from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import useScroll from '../hooks/useScroll';
import { IntakeQuestion } from '../intake.data';
import { IntakeFormData } from '../IntakePage';
import Choices from './content/Choices';
import MultiSelect from './content/MultiSelect';
import Description from './content/Description';
import IntakeLabel from './IntakeLabel';

type IntakeSectionProps = {
	index: number;
	section: IntakeQuestion;
	register: UseFormRegister<IntakeFormData>;
	setValue: UseFormSetValue<IntakeFormData>;
	trigger: UseFormTrigger<IntakeFormData>;
	errors: any;
	loading?: boolean;
	getValues: UseFormGetValues<IntakeFormData>;
};

const IntakeSection = ({
	index,
	section,
	register,
	setValue,
	trigger,
	errors,
	loading,
	getValues,
}: IntakeSectionProps) => {
	const { scroll } = useScroll();

	const [phoneValue, setPhoneValue] = useState<string>();

	const inputSubmit = async () => {
		if (!section.inputName) return scroll('down', index);

		const result = await trigger(section.inputName, { shouldFocus: true });
		if (!result) return console.log('Form Error');
		scroll('down', index);
	};

	const handleKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			inputSubmit();
		}
	};

	useEffect(() => {
		setValue('phone', phoneValue ?? '');
	}, [phoneValue, setValue]);

	return (
		<div id={index.toString()} className="w-full h-screen">
			<div
				className={`container mx-auto flex flex-col xl:flex-row ${
					section.svg
						? 'xl:justify-between justify-center'
						: 'justify-center'
				} items-center h-screen overflow-hidden xl:px-10 px-4`}
			>
				{section.svg && (
					<img
						src={section.svg}
						alt={section.label.toString()}
						className="xl:w-full xl:block hidden"
					/>
				)}
				<div
					className="flex flex-col font-button w-full"
					style={{ maxWidth: 700 }}
				>
					<IntakeLabel section={section} getValues={getValues} />

					<Description description={section.description} />

					{section.type === 'input' && (
						<Input
							refIndex={index}
							onKeyPress={handleKeyPress}
							name={section.inputName}
							register={register}
							className="text-2xl px-2"
						/>
					)}

					{section.type === 'phone' && (
						<PhoneInput
							id={`input-${index}`}
							onKeyPress={handleKeyPress}
							international
							countryCallingCodeEditable={false}
							defaultCountry="US"
							value={phoneValue ?? ''}
							onChange={(x) => setPhoneValue(x)}
						/>
					)}

					{/* CHOICES */}
					{section.type === 'choices' &&
						section.choices &&
						section.inputName &&
						section.choices.length > 0 && (
							<Choices
								choices={section.choices}
								inputName={section.inputName}
								setValue={setValue}
							/>
						)}

					{/* Multi Select */}
					{section.type === 'multi-select' &&
						section.choices &&
						section.inputName &&
						section.choices.length > 0 && (
							<MultiSelect
								choices={section.choices}
								inputName={section.inputName}
								min={section.min}
								setValue={setValue}
							/>
						)}

					{/* ERRORS */}
					{section.inputName && errors[section.inputName] && (
						<p className="mt-4">
							{errors[section.inputName]?.message}
						</p>
					)}

					{/* BUTTON */}
					<div className="mt-6">
						{section.button === 'Submit' ? (
							<Button
								type="submit"
								className="text-xl"
								disabled={loading}
							>
								{loading ? 'Loading' : 'Submit'}
							</Button>
						) : (
							!section.choices && (
								<Button
									type="button"
									onClick={inputSubmit}
									className="text-xl"
								>
									{section.button}
								</Button>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default IntakeSection;
