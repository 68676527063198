import React, { useContext } from 'react';
import useScroll from '../hooks/useScroll';
import { IntakeContext } from '../intake.provider';
import { ReactComponent as Up } from '../../../assets/icons/chevron-up.svg';

const Navigators = () => {
	const { currentIndex } = useContext(IntakeContext);
	const { scroll } = useScroll();

	return (
		<div className="fixed bottom-10 right-10">
			<button
				type="button"
				className="bg-oldYellowHighlight px-2 py-2 rounded-md"
				onClick={() => scroll('up', currentIndex)}
			>
				<Up />
			</button>
		</div>
	);
};

export default Navigators;
