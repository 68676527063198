import React from 'react';

const IntakeBackground = () => {
	return (
		<div
			className="fixed top-0 left-0 w-full h-screen"
			style={{
				backgroundImage:
					'url("https://images.typeform.com/images/TvBPTTRtixzR/background/large")',
				zIndex: -1,
			}}
		/>
	);
};

export default IntakeBackground;
