import { UserMobileOS } from './types/intake.types';

export type InputName =
	| 'firstName'
	| 'lastName'
	| 'email'
	| 'phone'
	| 'education'
	| 'social'
	| 'generation'
	| 'mobileOS'
	| 'howCanUplevylElevateYou';

export type IntakeQuestion = {
	type: 'text' | 'input' | 'choices' | 'phone' | 'multi-select';
	label: string | string[];
	inputName?: InputName;
	description: string[];
	button?: string;
	choices?: string[];
	svg?: string;
	min?: number;
	required: boolean;
	injectVariable?: {
		from: InputName;
		to: string;
	};
};

const data: IntakeQuestion[] = [
	{
		type: 'text',
		label: 'We’re so happy you’re here!',
		description: [
			'Like you, Uplevyl is dynamic and amazing, with so much to offer.  Because we have that in common, we’d like to know more about you.',
			'However, before we get this conversation started, you should know that we have the highest privacy and encryption standards to keep your personal credentials secure.',
			'We love data, especially data that helps women. So here’s a fun fact: How many global digital platforms are built by women specifically for professional women to help them navigate life? *Zero*. Until now!',
			'Uplevyl is the very first platform for professional women designed to access personalized content, experts, connections, and resources.',
		],
		button: 'Continue',
		required: false,
		svg: '/assets/intake/intro.png',
	},
	{
		type: 'text',
		label: 'The next step is to complete our membership application form (don’t worry—five minutes tops).',
		description: [
			'Please note, we have the highest standards of privacy and encryption to protect your personal information. Knowing a little bit about you will help us personalize content just for you.',
			'Ready to Uplevyl? Let’s get started.',
		],
		button: 'Begin Application',
		required: false,
	},
	{
		type: 'input',
		label: [
			'We’re excited to get to know you.',
			'Let’s get started! What’s your first name?',
		],
		inputName: 'firstName',
		svg: '/assets/intake/book.png',
		description: [],
		button: 'OK',
		required: true,
	},
	{
		type: 'input',
		label: [
			"Welcome, {FIRST NAME}! Here's another easy one:",
			"What's your last name?",
		],
		inputName: 'lastName',
		description: [],
		button: 'OK',
		required: true,
		injectVariable: {
			from: 'firstName',
			to: '{FIRST NAME}',
		},
	},
	{
		type: 'input',
		label: 'What’s the best email to reach you?',
		inputName: 'email',
		description: [],
		button: 'OK',
		required: true,
	},
	{
		type: 'phone',
		label: 'What’s the best contact number for you?',
		svg: '/assets/intake/pc.png',
		inputName: 'phone',
		description: [
			'This will help prevent fraud & helps us verify you as a valid user',
		],
		button: 'OK',
		required: true,
	},
	{
		type: 'input',
		label: [
			'Please share your LinkedIn profile, professional handles, or personal website.',
			'(If you don’t have the above, please share your workplace.)',
		],
		inputName: 'social',
		description: [],
		button: 'OK',
		required: true,
	},
	{
		type: 'choices',
		label: "What's your mobile OS?",
		button: 'OK',
		inputName: 'mobileOS',
		description: [],
		choices: [UserMobileOS.APPLE, UserMobileOS.ANDROID],
		required: true,
	},
	{
		type: 'choices',
		label: "What's the highest level of education you've achieved?",
		inputName: 'education',
		description: [],
		button: 'OK',
		choices: [
			'Some or No College',
			"Associate's Degree or Apprenticeship",
			"Bachelor's Degree",
			"Master's Degree",
			'Doctoral or Professional Degree',
		],
		required: true,
	},

	{
		type: 'choices',
		label: [
			'We hope to represent and build bridges between all generations of women.',
			'Which generation are you part of?',
		],
		inputName: 'generation',
		description: [],
		button: 'OK',
		choices: ['Baby Boomer', 'Gen X', 'Millennial', 'Gen Z'],
		required: true,
	},
	{
		type: 'multi-select',
		label: ['How can Uplevyl help you elevate your life?'],
		inputName: 'howCanUplevylElevateYou',
		description: ['Select your top 3'],
		button: 'OK',
		min: 3,
		choices: [
			'Improve professional skills',
			'Grow personally',
			'Increase financial knowledge',
			'Connect with like-minded women for personal and professional reasons',
			'Search for job opportunities',
			'Engage in thoughtful and relevant conversations',
			'Expert advice and resources',
			"I don't know yet",
		],
		required: true,
	},
	{
		type: 'text',
		label: [
			'Please read our <a href="https://www.uplevyl.com/terms" target="_blank" style="text-decoration: underline;">Terms and Conditions</a>,',
			'then agree by clicking submit.',
		],
		description: [],
		button: 'Submit',
		required: false,
	},
];

export default data;
