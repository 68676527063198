import React from 'react';

type DescriptionProps = {
	description: string[];
};

const Description = ({ description }: DescriptionProps) => {
	if (description.length === 0) return null;
	return (
		<div>
			{description.map((line) => (
				<p
					className="lg:text-xl text-lg my-2 text-description"
					key={line}
				>
					{line}
				</p>
			))}
		</div>
	);
};

export default Description;
