/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const ConfirmationPage = () => {
	const params = useParams();
	const [showCopied, setShowCopied] = useState<boolean>(false);

	const newLink = `https://${window.location.hostname}#invitee=${params.username}`;

	const handleClick = () => {
		setShowCopied(true);
		navigator.clipboard.writeText(newLink);
		setTimeout(() => {
			setShowCopied(false);
		}, 1000);
	};

	return (
		<main className="w-screen h-screen flex justify-center items-center font-body text-black bg-floral-size bg-pattern bg-center">
			<div className="w-full lg:max-w-md flex flex-col justify-center items-center p-10 text-left z-10">
				<img
					alt="Uplevyl"
					className="w-1/2 mb-8"
					src="https://cdn.uplevyl.com/logo/full/blue.png"
				/>
				<h1 className="mb-5 text-lg font-bold">
					Hooray 🥳! You&apos;re on the waitlist!
				</h1>
				<h2 className="mb-5">
					We received your application and are currently reviewing it.
					Once approved, you&apos;ll receive an email with detailed
					instructions for logging in and setting up your account so
					you can start Uplevyling right away.
					<br />
					Know other women who are ready to Uplevyl?
					<br />
					Share this unique referral link so you and a friend can
					learn, connect, and enjoy our platform. We&apos;re
					redesigning the world for women - join us!
				</h2>

				<div className="flex flex-wrap flex-row justify-center">
					<div
						className="border-black border rounded py-2 px-5 break-all cursor-pointer mb-5 text-sm mx-2"
						onClick={handleClick}
					>
						{newLink}
					</div>
					<button
						className="text-white py-2 bg-black rounded px-5 cursor-pointer mb-5 mx-2"
						onClick={handleClick}
					>
						{showCopied ? 'Copied!' : 'Copy'}
					</button>
				</div>
			</div>
		</main>
	);
};

export default ConfirmationPage;
