import { gql } from 'urql';

export default {
	activate: gql`
		mutation activateUsers($input: AdminUsersActivateInput!) {
			adminUsersActivate(input: $input) {
				id
				active
			}
		}
	`,
	archive: gql`
		mutation archiveUsers($input: AdminUsersArchiveInput!) {
			adminUsersArchive(input: $input) {
				id
				archive
			}
		}
	`,
	addRegistration: gql`
		mutation addRegistration($id: ID!, $input: AdminJoinLivingRoomInput!) {
			adminLivingRoomRegister(id: $id, input: $input) {
				id
				registrations {
					id
				}
			}
		}
	`,
	removeRegistration: gql`
		mutation removeRegistration(
			$id: ID!
			$input: AdminUnjoinLivingRoomInput!
		) {
			adminLivingRoomUnregister(id: $id, input: $input) {
				id
				registrations {
					id
				}
			}
		}
	`,
	userImportCsv: gql`
		mutation userImportCsv($url: String!, $enterpriseName: String) {
			userImportCsv(enterpriseName: $enterpriseName, url: $url)
		}
	`,
	userExportCsv: gql`
		query userExportCsv($filter: ConnectionFilter, $ids: [String!]) {
			adminUserExportCsv(filter: $filter, ids: $ids)
		}
	`,
	livingRoomExportCsv: gql`
		query livingRoomExportCsv($filter: ConnectionFilter, $ids: [String!]) {
			adminLivingRoomExportCsv(filter: $filter, ids: $ids)
		}
	`,
	reportExportCsv: gql`
		query reportExportCsv($filter: ConnectionFilter, $ids: [String!]) {
			adminReportExportCsv(filter: $filter, ids: $ids)
		}
	`,
	broadcastNotification: gql`
		mutation broadcastNotification(
			$body: String!
			$subtitle: String!
			$title: String!
		) {
			adminBroadcast(body: $body, subtitle: $subtitle, title: $title)
		}
	`,
	pollCreate: gql`
		mutation createPoll($input: PollCreateInput!) {
			pollCreate(input: $input) {
				id
			}
		}
	`,
	quoteCreate: gql`
		mutation createQuote($input: QuoteCreateInput!) {
			quoteCreate(input: $input) {
				id
			}
		}
	`,
	freeTextCreate: gql`
		mutation createFreeText($input: FreeTextCreateInput!) {
			freeTextCreate(input: $input) {
				id
			}
		}
	`,
	contentRequestMasterAccess: gql`
		mutation contentRequestMasterAccess($id: ID!) {
			contentRequestMasterAccess(id: $id)
		}
	`,
};
