import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import IntakeSection from './components/IntakeSection';
import Navigators from './components/Navigators';
import useScroll from './hooks/useScroll';
import sections from './intake.data';
import IntakeBackground from './components/IntakeBackground';
import { useCreateUserMutation } from './hooks/useCreateUserMutation';
import { toEnum, UserGeneration, UserMobileOS } from './types/intake.types';
import ErrorPopup from './components/ErrorPopup';
import { IntakeContext } from './intake.provider';
import { useAnalyticsService } from './hooks/useAnalyticsService';

type IntakePageProps = {
	hash?: string | undefined;
};

export type IntakeFormData = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	education: string;
	social: string;
	generation: UserGeneration;
	mobileOS: UserMobileOS;
	howCanUplevylElevateYou?: string[];
};

const phoneRegExp =
	/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

const schema = yup.object().shape({
	firstName: yup.string().required('This is a required field'),
	lastName: yup.string().required('This is a required field'),
	email: yup.string().required('Email is required').email('Email is invalid'),
	phone: yup
		.string()
		.required('Phone number is required')
		.matches(phoneRegExp, 'Phone number is not valid'),
	social: yup.string().required('This is a required field'),
});

const IntakePage = ({ hash }: IntakePageProps) => {
	const { currentIndex } = useContext(IntakeContext);
	const { resetScroll } = useScroll();
	const navigate = useNavigate();
	const [createUser, { loading: submitting, error: createUserError }] =
		useCreateUserMutation();
	const [error, setError] = useState<string | undefined>();

	const {
		register,
		handleSubmit,
		setValue,
		trigger,
		formState: { errors },
		getValues,
	} = useForm<IntakeFormData>({
		resolver: yupResolver(schema),
	});

	const analytics = useAnalyticsService();

	const onSubmit = async (data: IntakeFormData) => {
		let inviteeToken: string | undefined;
		let inviteeUsername: string | undefined;

		const token = hash?.split('=')[1];
		if (token && isBase64(token)) {
			inviteeToken = token;
		} else if (token) {
			inviteeUsername = token;
		}

		try {
			const { data: createUserData } = await createUser({
				variables: {
					input: {
						email: data.email,
						firstName: data.firstName,
						lastName: data.lastName,
						education: toEnum(data.education),
						termsAgreed: true,
						phone: data.phone,
						profiles: data.social ?? '',
						generation: toEnum(data.generation),
						inviteeToken,
						inviteeUsername,
						mobileOS: toEnum(data.mobileOS) ?? UserMobileOS.APPLE,
						howCanUplevylElevateYou: data.howCanUplevylElevateYou,
					},
				},
			});

			await analytics.logScreenView({
				screen_name: 'intake form - page 10 - completion page',
			});

			await analytics.logEvent('intake_completed');

			navigate(`/referral/${createUserData?.userCreate.username}`);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		const fireAnalytics = async () => {
			switch (currentIndex) {
				case 2: // First Name Screen
					await analytics.logEvent('intake_started');
					return analytics.logScreenView({
						screen_name: 'intake form - page 1 - first name',
					});
				case 3: // Last Name Screen
					return analytics.logScreenView({
						screen_name: 'intake form - page 2 - last name',
					});
				case 4: // Email Screen
					return analytics.logScreenView({
						screen_name: 'intake form - page 3 - email',
					});
				case 5: // Phone Screen
					return analytics.logScreenView({
						screen_name: 'intake form - page 4 - phone',
					});
				case 6: // LinkedIn Work Screen
					return analytics.logScreenView({
						screen_name: 'intake form - page 7 - work',
					});
				case 8: // Education Screen
					return analytics.logScreenView({
						screen_name: 'intake form - page 6 - education',
					});
				case 9: // Generation Screen
					return analytics.logScreenView({
						screen_name: 'intake form - page 8 - generation',
					});
				case 11: // Terms & Conditions Screen
					return analytics.logScreenView({
						screen_name:
							'intake form - page 9 - term and conditions',
					});
				default:
					break;
			}
		};

		fireAnalytics();
	}, [currentIndex, analytics]);

	/**
	 * Check if a string is Base64 encoded or not
	 * @param value String value to check
	 * @returns `true` if base64, `false` if not
	 */
	const isBase64 = (value: string) =>
		/^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(
			value,
		);

	useEffect(() => {
		if (createUserError)
			setError(
				createUserError.graphQLErrors[0]?.extensions?.message ??
					createUserError.message,
			);
	}, [createUserError]);

	/* eslint-disable */
	useEffect(() => {
		resetScroll();
	}, []);

	return (
		<>
			<IntakeBackground />
			{error && <ErrorPopup error={error} setError={setError} />}
			<form onSubmit={handleSubmit(onSubmit)}>
				{sections.map((section, i) => (
					<IntakeSection
						key={section.label.toString()}
						section={section}
						index={i}
						register={register}
						setValue={setValue}
						trigger={trigger}
						errors={errors}
						loading={submitting}
						getValues={getValues}
					/>
				))}
				<Navigators />
			</form>
		</>
	);
};

export default IntakePage;
